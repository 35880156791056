import { selectorFamily } from 'recoil';
import { Category } from '../../cores/schema';
import { GET_CATEGORIES } from '../../cores/queries';
import { client } from '../../cores/client';
import { triggerState } from '../trigger';

export type CategoryState = Category[];

export const getCategory = selectorFamily<CategoryState, any>({
  key: 'getCategory',
  get: (nickname: string) => async ({ get }) => {
    get(triggerState('getCategory'));
    try {
      const {
        data: { getAnonymousCategories },
      } = await client.query<{ getAnonymousCategories: Category[] }>({
        query: GET_CATEGORIES,
        fetchPolicy: 'no-cache',
        variables: {
          nickname
        }
      });

      return getAnonymousCategories;
    } catch (e) {
      return [];
    }
  },
});
