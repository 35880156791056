import React, { FC, memo } from 'react';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  nickname?: string;
  profileSrc?: string;
}

const Header: FC<Props> = memo(({ nickname, profileSrc }) => {
  return (
    <div className={styles.header}>
      <Link to={{ pathname: `/${nickname}` }} className={styles.logo} style={{ backgroundImage: profileSrc ? `url(${profileSrc})` : '' }}>

      </Link>
    </div>
  );
});

export default Header;
