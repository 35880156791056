import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import fetch from 'cross-fetch';
import { GRAPHQL_HOST } from './constants';

const timeoutLink = new ApolloLinkTimeout(30000);

const uploadLink = createUploadLink({
  fetch,
  fetchOptions: {
    credentials: 'include',
  },
  uri: GRAPHQL_HOST,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!graphQLErrors) {
    return;
  }
});

export const client = new ApolloClient({
  link: from([errorLink, timeoutLink, uploadLink as any]),
  cache: new InMemoryCache({
  }),
});
