import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import styles from "./SceneDetail.module.scss";
import { useQuery } from "@apollo/client";
import { Scene } from "../../cores/schema";
import { GET_ANONYMOUS_SCENE } from "../../cores/queries";
import get from "lodash.get";
import { Portal } from "react-portal";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { VIEWER_HOST } from "../../cores/constants";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useSpring, config, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import Scrollbars from "react-custom-scrollbars";
import { useScale } from '../../cores/useScale';
import { useWindowSize } from 'react-use';

interface Props {
  hashId: string | null;
  isHidePrice: boolean;
}

const SceneDetail: FC<Props> = memo(({ hashId, isHidePrice }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { data, loading } = useQuery<{ getAnonymousScene: Scene }>(
    GET_ANONYMOUS_SCENE,
    {
      fetchPolicy: !hashId ? "cache-only" : "cache-and-network",
      errorPolicy: "all",
      variables: {
        hashId: hashId,
      },
    }
  );

  const { search } = useLocation();
  const scrollYRef = useRef<number>(window.scrollY);
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const scene = useMemo(() => get(data, "getAnonymousScene"), [data]);
  const { height: screenHeight } = useWindowSize();

  const leftHeight = useScale(13);
  const height = useMemo(() => screenHeight - leftHeight, [screenHeight, leftHeight]);

  const [{ y }, set] = useSpring(() => ({ y: height }));

  const open = () => {
    set({
      y: 0,
      immediate: false,
      config: {
        tension: 400,
        friction: 40,
      },
    });
  };

  const close = (velocity = 0) => {
    if (hashId) {
      query.delete("scene");
      history.push(`?${query.toString()}`);
      document.body.scrollTo(0, scrollYRef.current);
    }

    set({ y: height, immediate: false, config: { ...config.stiff, velocity } });
  };

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, canceled }: any) => {
      if (my < -70) cancel();

      if (last) {
        my > height * 0.4 || vy > 0.4 ? close(vy) : open();
      } else {
        set({ y: my, immediate: true })
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  const display = y.to((py) => (py < height ? "block" : "none"));

  const bgStyle = {
    opacity: y.to([0, height], [1.0, 0.0], "clamp"),
    pointerEvents: hashId ? "auto" : "none",
  };

  const renderedCategory = useMemo(() => {
    if (!scene || !scene.category || !scene.category.parent) {
      return null;
    }

    return (
      <div className={styles.categories}>
        <Link to={{ search: `?category=${scene.category.parent.hashId}` }}>
          {scene.category.parent.name}
        </Link>
        <div>
          <MdKeyboardArrowRight />
        </div>
        <Link
          className={styles.category}
          to={{ search: `?category=${scene.category.hashId}` }}
        >
          {scene.category.name}
        </Link>
      </div>
    );
  }, [scene]);

  useEffect(() => {
    if (hashId) {
      scrollYRef.current = window.scrollY;
      disableBodyScroll(document.body);
      open();
    } else {
      enableBodyScroll(document.body);
      close();
    }
  }, [hashId, open, close]);

  const iframeSrc = useMemo(() => {
    if (!hashId) {
      return null;
    }

    if (!VIEWER_HOST.endsWith("/")) {
      return `${VIEWER_HOST}/${hashId}`;
    }

    return VIEWER_HOST + hashId;
  }, [hashId]);

  const renderedInformation = useMemo(() => {
    if (!scene) {
      return null;
    }

    return (
      <div className={styles.information}>
        <h2 className={styles.brand}>{scene.brand}</h2>
        <h1 className={styles.name}>{scene.name}</h1>
        {!isHidePrice && <p className={styles.price}>{scene.price}</p>}
        <div className={styles.description}>
          <Scrollbars>{scene.description}</Scrollbars>
        </div>
      </div>
    );
  }, [scene]);

  const handleActivateAR = useCallback(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) {
      return;
    }

    iframeRef.current.contentWindow.postMessage({ type: "activateAR" }, "*");
  }, []);

  return (
    <Portal>
      <animated.div
        className={styles.dimmer}
        onClick={() => {
          close();
        }}
        style={bgStyle}
      />
      <animated.div style={{ display, y, height: `calc(100% - ${leftHeight}px)` }} className={styles.sceneDetail}>
        <div className={styles.bar} {...bind()} />
        <div className={styles.inner}>
          {renderedCategory}
          {iframeSrc && (
            <iframe ref={iframeRef} className={styles.viewer} src={iframeSrc} allowFullScreen allow="xr-spatial-tracking" />
          )}
          {renderedInformation}
        </div>
        <div className={styles.actions}>
          <div className={styles.buttons}>
            <button onClick={handleActivateAR} className={styles.button}>
              AR VIEW
            </button>
            {scene && (
              <a className={styles.button} href={scene.externalLink || ""}>
                구매하기
              </a>
            )}
          </div>
        </div>
      </animated.div>
    </Portal>
  );
});

export default SceneDetail;
