import React, { FC, memo, useMemo } from 'react';
import styles from './SceneItem.module.scss';
import { Scene } from '../../cores/schema';
import { useThumbnail } from '../../cores/useThumbnail';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-use';
import classNames from 'classnames';

interface Props {
  odd: boolean;
  data: Scene;
  isHidePrice: boolean;
}

const SceneItem: FC<Props> = memo(({ data, odd, isHidePrice }) => {
  const { search } = useLocation();
  const thumbnailSrc = useThumbnail(data.thumbnail);
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const toSearch: string = useMemo(() => {
    query.set('scene', data.hashId);
    return query.toString();
  }, [query]);

  return (
    <div className={classNames(styles.sceneItem, {
      [styles.odd]: odd
    })}>
      <Link to={{ search: toSearch }} className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.thumbnail} style={{ backgroundImage: thumbnailSrc ? `url(${thumbnailSrc})` : '' }} />
        </div>
        <div className={styles.information}>
          <h1 className={styles.brand}>
            {data.brand}
          </h1>
          <p className={styles.name}>
            {data.name}
          </p>
          {!isHidePrice && <p className={styles.price}>
            {data.price}
          </p>}
        </div>
      </Link>
    </div>
  );
});

export default SceneItem;
