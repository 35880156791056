import React, { FC, memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styles from './CategoryItem.module.scss';
import { Category } from '../../cores/schema';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import get from 'lodash.get';

interface Props {
  isChildren?: boolean;
}

interface Props {
  category?: Category;
}

const CategoryItem: FC<Props> = memo(({ category, isChildren }) => {
  const { search } = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const hashId = useMemo(() => get(category, 'hashId'), [category]);
  const name = useMemo(() => get(category, 'name') || 'ALL', [category]);
  const key = useMemo(() => isChildren ? "subcategory" : "category", [isChildren]);

  const isSelected = useMemo(() => {
    if (!category && query.get(key) === null) {
      return true;
    }

    return query.get(key) === hashId;
  }, [category, query, key])

  const toSearch: string = useMemo(() => {
    if (!isChildren) {
      query.delete('subcategory')
    }

    if (hashId) {
      query.set(key, hashId);
    } else {
      query.delete(key);
    }
    return query.toString();
  }, [query, key, hashId, isChildren]);

  return (
    <Link to={{ search: toSearch }} className={classNames(styles.categoryItem, isSelected && styles.isSelected)}>
      <div ref={ref} className={styles.inner}>
        {name}
      </div>
    </Link>
  );
});

export default CategoryItem;
