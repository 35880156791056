import { useMemo } from 'react';
import { MEDIA_HOST } from './constants';

export function useAsset(prefix: string, key?: string | null) {
  return useMemo(() => {
    if (!key) {
      return '';
    }

    if (!MEDIA_HOST.endsWith('/')) {
      return `${MEDIA_HOST}/${prefix}/${key}`;
    }

    return `${MEDIA_HOST}${prefix}/${key}`;
  }, [prefix, key]);
}
