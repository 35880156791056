import React, { FC, memo } from 'react';
import styles from './App.module.scss';
import Collection from '../Collection/Collection';
import Header from '../Header/Header';
import { Route, Switch } from 'react-router-dom';

interface Props {

}

const App: FC<Props> = memo(() => {
  return (
    <div className={styles.app}>
      <Switch>
        <Route path="/:nickname" component={Collection} />
      </Switch>
    </div>
  )
});

export default App;
