import React, { FC, memo, useMemo, useRef, useState } from 'react';
import CategoryItem from '../CategoryItem/CategoryItem';
import styles from './Categories.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { Category } from '../../cores/schema';

interface Props {
  data: Category[];
  isChildren?: boolean;
}

const Categories: FC<Props> = memo(({ data, isChildren }) => {
  const categoryItems = useMemo(() => {
    return data.map(category => {
      return <CategoryItem key={category.hashId} category={category} isChildren={isChildren} />
    });
  }, [data, isChildren]);

  return (
    <div className={styles.categories}>
      <Scrollbars>
        <div className={styles.inner}>
          <CategoryItem isChildren={isChildren} />
          {categoryItems}
        </div>
      </Scrollbars>
    </div>
  );
});

export default Categories;
