import { useMemo } from 'react';
import { MEDIA_HOST } from '../cores/constants';

export function useThumbnail(key?: string | null) {
  return useMemo(() => {
    if (!key) {
      return '';
    }

    if (!MEDIA_HOST.endsWith('/')) {
      return `${MEDIA_HOST}/thumbnails/${key}`;
    }

    return `${MEDIA_HOST}thumbnails/${key}`;
  }, [key]);
}
