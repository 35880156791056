import { gql } from '@apollo/client';

export const GET_PUBLIC_USER_INFORMATION_BY_NICKNAME = gql`
 query ($nickname: String!) {
   getPublicUserInformationByNickname(nickname: $nickname) {
     profile
   }
 }
`;

export const GET_CATEGORIES = gql`
  query ($nickname: String!) {
    getAnonymousCategories(nickname: $nickname) {
      count
      hashId
      name
      children {
        count
        hashId
        name
        parent {
          hashId
        }
      }
    }
  }
`;

export const GET_ANONYMOUS_PAGINATED_SCENE = gql`
  query ($nickname: String!, $first: Int!, $after: String, $category: ID) {
    getAnonymousPaginatedScenes(nickname: $nickname, first: $first, after: $after, category: $category) {
      leftCount
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          hashId
          category {
            hashId
            name
            parent {
              hashId
              name
            }
          }
          name
          description
          thumbnail
          brand
          price
          createdAt
        }
      }
    }
  }
`;

export const GET_ANONYMOUS_SCENE = gql`
  query ($hashId: ID!) {
    getAnonymousScene(hashId: $hashId) {
      hashId
      category {
        hashId
        name
        parent {
          hashId
          name
        }
      }
      name
      description
      thumbnail
      brand
      price
      createdAt
    }
  }
`;
