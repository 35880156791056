import React, { FC, memo, useEffect, useMemo } from "react";
import Categories from "../Categories/Categories";
import styles from "./Collection.module.scss";
import { useLocation, useParams } from "react-router-dom";
import get from "lodash.get";
import uniqBy from "lodash.uniqby";
import { useKeepRecoilValue } from "../../cores/useKeepRecoilValue";
import { getCategory } from "../../atoms/selectors/getCategory";
import { useLoadMore } from "../../cores/useLoadMore";
import { PaginatedScene } from "../../cores/schema";
import { useQuery } from "@apollo/client";
import {
  GET_ANONYMOUS_PAGINATED_SCENE,
  GET_PUBLIC_USER_INFORMATION_BY_NICKNAME,
} from "../../cores/queries";
import produce from "immer";
import SceneItem from "../SceneItem/SceneItem";
import classNames from "classnames";
import SceneDetail from "../SceneDetail/SceneDetail";
import Header from "../Header/Header";
import { useAsset } from '../../cores/useAsset';

interface Props {}

const hidePriceTargets = [
  "plotica"
];

const Collection: FC<Props> = memo(({}) => {
  const params = useParams();
  const nickname = useMemo(() => get(params, "nickname"), [params]);
  const [categories, , isLoading] = useKeepRecoilValue(
    getCategory(nickname),
    []
  );
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const scene = useMemo(() => query.get("scene"), [query]);
  const category = useMemo(() => query.get("category"), [query]);
  const subcategory = useMemo(() => query.get("subcategory"), [query]);

  const { data: profile } = useQuery<{
    getPublicUserInformationByNickname: any;
  }>(GET_PUBLIC_USER_INFORMATION_BY_NICKNAME, {
    errorPolicy: "all",
    variables: {
      nickname,
    },
  });

  const isHidePrice = useMemo(() => {
    return hidePriceTargets.indexOf(nickname) !== -1;
  }, [nickname]);

  const { data, fetchMore, loading } = useQuery<{
    getAnonymousPaginatedScenes: PaginatedScene;
  }>(GET_ANONYMOUS_PAGINATED_SCENE, {
    errorPolicy: "all",
    variables: {
      first: 20,
      nickname,
      category: subcategory ? subcategory : category,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category]);

  useLoadMore(() => {
    if (
      !data ||
      !data.getAnonymousPaginatedScenes.pageInfo ||
      !data.getAnonymousPaginatedScenes.pageInfo.hasNextPage ||
      loading
    ) {
      return;
    }

    fetchMore({
      variables: {
        first: 20,
        after: data.getAnonymousPaginatedScenes.pageInfo.endCursor,
        nickname,
        category: subcategory ? subcategory : category,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return produce(previousResult, (draft) => {
          if (
            !previousResult ||
            !fetchMoreResult ||
            !previousResult.getAnonymousPaginatedScenes ||
            !fetchMoreResult.getAnonymousPaginatedScenes ||
            !previousResult.getAnonymousPaginatedScenes.edges ||
            !fetchMoreResult.getAnonymousPaginatedScenes.edges
          ) {
            return;
          }

          draft.getAnonymousPaginatedScenes.pageInfo =
            fetchMoreResult.getAnonymousPaginatedScenes.pageInfo;
          draft.getAnonymousPaginatedScenes.edges = uniqBy(
            [
              ...previousResult.getAnonymousPaginatedScenes.edges,
              ...fetchMoreResult.getAnonymousPaginatedScenes.edges,
            ],
            (o) => o.node.hashId
          );
        });
      },
    });
  });

  const subCategories = useMemo(() => {
    if (!category) {
      return [];
    }

    const findSubCategory = categories.find(c => c.hashId === category);
    if (findSubCategory) {
      return findSubCategory.children;
    }

    return [];
  }, [categories, category]);

  const renderedSceneItems = useMemo(() => {
    if (!data || !data.getAnonymousPaginatedScenes.edges) {
      return [];
    }

    return data.getAnonymousPaginatedScenes.edges.map((edge, key) => {
      return (
        <SceneItem
          key={edge.node.hashId}
          odd={key % 2 === 1}
          data={edge.node}
          isHidePrice={isHidePrice}
        />
      );
    });
  }, [data, isHidePrice]);

  const profileSrc = useAsset('profiles', get(profile, ['getPublicUserInformationByNickname', 'profile']));

  if (!nickname) {
    return null;
  }

  console.log(subCategories)
  return (
    <div
      className={classNames(styles.collection, category && styles.hasCategory)}
    >
      <Header nickname={nickname} profileSrc={profileSrc} />
      <div className={styles.header}>
        <Categories data={categories} />
        {category && <Categories data={subCategories} isChildren />}
      </div>
      <div className={styles.sceneItems}>{renderedSceneItems}</div>
      <SceneDetail hashId={scene} isHidePrice={isHidePrice} />
    </div>
  );
});

export default Collection;
